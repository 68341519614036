* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  width: 100%;
  background-color: #f8f7f7;
  -webkit-tap-highlight-color: transparent;
}


h2 {
  font-family: 'Work Sans', sans-serif;
  color: #8a8a8b;
  font-weight: 400;
  font-size: 20px;
}

h3, p, h4, button, input, textarea, footer {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

p {
  font-size: 12px;
  color: #919190;
}

h3 {
  font-size: 15px;
  color: #919190;
}

.add-friend-overlay-title {
  text-align: center;
}

input, textarea {
  font-size: 13px;
}

input:-webkit-autofill {
  background-clip: text;
  -webkit-text-fill-color: #919190;
}

footer {
  margin-top: 10px;
  font-size: 13px;
  color: #919190;
}

button {
  font-size: 13px;
  background-color: #ea738d;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: 6px 8px;
}

.card-container {
  background-color: #9dbbf8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: #b52f06f0;
  background-color: #fee3e2;
  border-radius: 4px;
  padding: 3px 9px;
  margin-top: 0;
  font-size: 9px;
}

.server-error {
  margin-top: 5px;
}

.dark-icon {
  color: #8a8a8b;
  height: 13px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.clickable {
  cursor: pointer
}

.light-icon {
  color: #919190;
}

.message-icon {
  height: 70px;
}

.auth-a-tag {
  color: #ea738dff;
  font-weight: bold;
}

.add-friend-icon {
  font-size: 17px;
}

.x-icon {
  display: flex;
  margin-right: 10px;
  justify-self: flex-end;
  cursor: pointer;
  font-size: 20px;
}

.x-icon-search {
  display: flex;
  margin-right: 10px;
  justify-self: flex-end;
  cursor: pointer;
  font-size: 18px;
}

.full-screen-error-container {
  background-color: #9dbbf8;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.full-screen-error-container h2 {
  margin-bottom: 20px;
  font-size: 17px;
}

.full-screen-error-button {
  font-size: 11px;
  border-radius: 8px;
}

.full-screen-error-container button {
  min-width: 80px;
}

.small-black-title {
  font-size: 13px;
  color: black;
  font-weight: 500;
}

.full-screen-overlay-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.x-mark-overlay-container {
  display: flex;
  align-self: flex-end;
  margin: 0px 20px 0 0;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #919190;
  border-radius: 8px;
}

@media screen and (min-width: 768px) {
  button {
    font-size: 15px;
    padding: 6px 12px;
  }

  h2 {
    font-size: 23px;
  }

  h3 {
    font-size: 17px;
  }

  .add-friend-icon {
    font-size: 18px;
  }

  .x-icon {
    font-size: 23px;
  }

  .small-black-title {
    font-size: 14px;
  }

  .message-icon {
    height: 76px;
  }

  .error-message {
    font-size: 11px;
    margin-top: 2px;
    padding: 4px 9px;
    border-radius: 6px;
  }

  .full-screen-error-button {
    font-size: 15px;
    padding: 6px 12px;
    border-radius: 10px;
  }

  .full-screen-error-container h2 {
    font-size: 23px;
  }
  
}

@media screen and (min-width: 1024px) {
  h2 {
    font-size: 26px;
  }

  p {
    font-size: 15px;
  }

  input, textarea {
    font-size: 15px;
  }
  
  .message-icon {
    height: 90px;
  }

  .small-black-title {
    font-size: 15px;
  }

  .x-icon-search {
    font-size: 20px;
  }

  .error-message {
    font-size: 13px;
    padding: 7px 12px;
    margin-top: 0;
  }
  
  button:hover {
    background-color: #9dbbf8;
  }

  footer {
    font-size: 15px;
  }

  .full-screen-error-container button:hover {
    background-color: #ea738dff;
    transform: scale(1.1);
  }
}